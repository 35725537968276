import classNames from "classnames";
import type { ReactNode, ReactElement } from "react";
import { useMemo } from "react";

export type Direction = "row" | "col";

export type Gap = "0" | "1" | "2" | "4" | "8";

export type Justify =
  | "start"
  | "end"
  | "center"
  | "stretch"
  | "around"
  | "between";

export type Items = "start" | "end" | "center" | "stretch";

export interface StackProps {
  direction: Direction;
  gap?: Gap;
  items?: Items;
  justify?: Justify;
  collapsible?: boolean;
  className?: string;
  children: ReactNode;
}

export default function Stack(props: StackProps): ReactElement {
  const {
    direction,
    gap = "0",
    items = "stretch",
    justify = "center",
    collapsible = false,
    className: _className,
    children,
  } = props;

  const className = useMemo(
    () =>
      classNames([
        "flex",
        collapsible
          ? collapsibleDirectionClasses[direction]
          : directionClasses[direction],
        gapClasses[gap],
        justifyClasses[justify],
        itemsClasses[items],
        _className,
      ]),
    [collapsible, direction, gap, items, justify, _className]
  );

  return <div className={className}>{children}</div>;
}

export function HStack(props: Omit<StackProps, "direction">): ReactElement {
  return <Stack direction="row" {...props} />;
}

export function VStack(props: Omit<StackProps, "direction">): ReactElement {
  return <Stack direction="col" {...props} />;
}

const directionClasses: Record<Direction, string> = {
  row: "flex-row",
  col: "flex-col",
};

const collapsibleDirectionClasses: Record<Direction, string> = {
  row: "flex-col md:flex-row",
  col: "flex-col",
};

const gapClasses: Record<Gap, string> = {
  "0": "gap-0",
  "1": "gap-1",
  "2": "gap-2",
  "4": "gap-4",
  "8": "gap-8",
};

const justifyClasses: Record<Justify, string> = {
  start: "justify-start",
  end: "justify-end",
  center: "justify-center",
  stretch: "justify-stretch",
  around: "justify-around",
  between: "justify-between",
};

const itemsClasses: Record<Items, string> = {
  start: "items-start",
  end: "items-end",
  center: "items-center",
  stretch: "items-stretch",
};
